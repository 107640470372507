// services/PersonaServices.ts
import { Persona } from '../types';

class PersonaServices {
	// Fetch a list of personas
	async getPersonas(bootstrap_version: string = '2'): Promise<Persona[]> {
		try {
			const response = await fetch(`/api/getPersonas?bootstrap_version=${bootstrap_version}`);
			if (!response.ok) {
				throw new Error(`Failed to fetch personas: ${response.statusText}`);
			}

			const data = await response.json();

			if (Array.isArray(data) && data.every(item => this.isPersona(item))) {
				return data.map(this.parsePersonaId);
			} else {
				console.error('Invalid data format received:', data);
				throw new Error('Invalid data format received from API');
			}
		} catch (error) {
			console.error('Error in getPersonas:', error);
			throw error;
		}
	}

	// Fetch a single persona by ID and optionally specify a bootstrap version
	async getPersona(personaId: string, bootstrapVersion: string = '2'): Promise<Persona> {
		try {
			const url = new URL('/api/getPersona', window.location.origin);
			url.searchParams.append('personaId', personaId);
			url.searchParams.append('bootstrap_version', bootstrapVersion);

			const response = await fetch(url.toString());
			if (!response.ok) {
				throw new Error(`Failed to fetch persona with ID ${personaId}: ${response.statusText}`);
			}

			const data = await response.json();

			// Map the API response fields to match the expected Persona structure
			const mappedData = this.mapResponseToPersona(data);

			if (this.isPersona(mappedData)) {
				return this.parsePersonaId(mappedData);
			} else {
				console.error('Invalid data format received:', mappedData);
				throw new Error('Invalid data format received from API');
			}
		} catch (error) {
			console.error(`Error in getPersona for ID ${personaId}:`, error);
			throw error;
		}
	}

	// Post a new persona to the API
	async postPersona(persona: Persona): Promise<Persona> {
		try {
			const response = await fetch('/api/postPersona', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json;charset=utf-8',
				},
				body: JSON.stringify(persona),
			});

			if (!response.ok) {
				throw new Error(`Failed to post persona: ${response.statusText}`);
			}

			const data = await response.json();
			console.log('Response data from postPersona:', data);

			if (this.isPersona(data)) {
				return this.parsePersonaId(data);
			} else {
				console.error('Invalid data format received:', data);
				throw new Error('Invalid data format received from API after posting persona');
			}
		} catch (error) {
			console.error('Error in postPersona:', error);
			throw error;
		}
	}

	async updatePersona(persona: Persona): Promise<Persona> {
		try {
			const response = await fetch('/api/updatePersona', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json;charset=utf-8',
				},
				body: JSON.stringify(persona),
			});

			if (!response.ok) {
				throw new Error(`Failed to update persona: ${response.statusText}`);
			}

			const data = await response.json();
			console.log('Response data from updatePersona:', data);

			if (this.isPersona(data)) {
				return this.parsePersonaId(data);
			} else {
				console.error('Invalid data format received:', data);
				throw new Error('Invalid data format received from API after updating persona');
			}
		} catch (error) {
			console.error('Error in updatePersona:', error);
			throw error;
		}
	}

	// Helper method to map the API response to match Persona structure
	private mapResponseToPersona(data: any): Persona {
		return {
			id: data.persona_id || data.id,
			version: data.version || '',
			name: data.model_name || '',
			author: data.provider || '',
			desc: data.system_message || '',
			html_desc: data.html_desc || '',
			html: data.html || '',
			bootstrap_version: data.bootstrap_version || '',
			created_at: data.created_at || 0,
			updated_at: data.updated_at || 0,
			modelName: data.model_name || '',
			provider: data.provider || '',
			systemMessage: data.system_message || '',
		};
	}

	// Helper method to check if an object is a Persona
	private isPersona(data: any): data is Persona {
		return (
			typeof data === 'object' && data !== null &&
			'id' in data &&
			'version' in data &&
			'name' in data &&
			'author' in data &&
			'desc' in data &&
			'bootstrap_version' in data &&
			'created_at' in data &&
			'updated_at' in data &&
			'modelName' in data &&
			'provider' in data &&
			'systemMessage' in data &&
			'html' in data &&
			'html_desc' in data
		);
	}

	// Helper method to parse and decompose the persona ID
	private parsePersonaId(persona: Persona): Persona {
		const [name, author, version] = persona.id.split('_');
		return {
			...persona,
			name: name || persona.name,
			author: author || persona.author,
			version: version || persona.version
		};
	}
}

export default new PersonaServices();
