import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import CreatePersona from './CreatePersona'; // Assuming this is the form component
import PersonaServices from '../services/PersonaServices'; // Assuming this is where your API call resides
import { Container } from '@chakra-ui/react';

const UpdatePersonaPage: React.FC = () => {
    const {personaName, author, version} = useParams(); // Extracting the route params
    const [personaData, setPersonaData] = useState<any | null>(null); // To store the persona data
    const [loading, setLoading] = useState(true); // Loading state

    // Fetch the persona data when the component loads
    useEffect(() => {
        async function fetchPersona() {
            try {
                // Construct personaId by combining personaName, author, and version
                const personaId = `${personaName}_${author}_${version}`;

                // Fetch the persona data using the constructed personaId
                const data = await PersonaServices.getPersona(personaId, 'web1'); // Assuming 'web1' is the bootstrap version
                setPersonaData(data); // Store the fetched data
            } catch (error) {
                console.error('Failed to fetch persona:', error);
            } finally {
                setLoading(false); // Set loading to false after the data is fetched
            }
        }

        fetchPersona();
    }, [personaName, author, version]);

    if (loading) {
        return <p>Loading persona details...</p>; // Display loading state
    }

    return (
        <Container maxW="container.lg" p={0}>
            <div>
                {personaData ? (
                    <CreatePersona personaData={personaData}/> // Pass fetched persona data to the form
                ) : (
                    <p>No persona data available</p> // Display error if no persona data
                )}
            </div>
        </Container>
    );
};

export default UpdatePersonaPage;
