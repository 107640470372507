// src/utils/DynamicComponentLoader.tsx

import React, { Suspense, lazy } from 'react';
import { Box, Spinner } from '@chakra-ui/react';

interface DynamicComponentProps {
    componentName: string;
    props?: any;
}

const DynamicComponent: React.FC<DynamicComponentProps> = ({ componentName, props }) => {
    const LazyComponent = lazy(() =>
        import(`../components/generated/${componentName}`)
            .then(module => ({ default: module.default }))
            .catch(() => ({
                default: () => <Box color="red.500">Failed to load component: {componentName}</Box>,
            }))
    );

    return (
        <Suspense fallback={<Spinner />}>
            <LazyComponent {...props} />
        </Suspense>
    );
};

export default DynamicComponent;
