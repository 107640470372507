import React, {useEffect} from 'react';
import {Box} from '@chakra-ui/react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Navbar from './components/Navbar';
import CommentSection from './components/CommentSection';
import NotFoundPage from './components/NotFoundPage'; // Import the NotFoundPage component
import 'katex/dist/katex.min.css'; // if you're using rehype-katex
// import 'highlight.js/styles/github.css'; // Import your preferred style
import hljs from 'highlight.js';
import 'highlight.js/styles/monokai.css'; // Import the Monokai theme
import Disclaimer from './components/Disclaimer';
import StreamingChat from "./components/StreamComment";
import CreatePersona from "./components/CreatePersona";
import PersonaRenderer from "./components/PersonaRenderer"; // Import Disclaimer component
import PersonaList from './components/PersonaList';
import UpdatePersonaPage from './components/UpdatePersonaPage';
import {Container} from '@chakra-ui/react';

function App() {
    useEffect(() => {
        hljs.highlightAll();
    }, []);

    const handleAddResponse = (response: string) => {
        // Do something with the complete response
        console.log("Complete response:", response);
    };

    return (
        <Router>
            <Navbar/>
            <Routes>
                <Route path="/topic/:topicId/:topicMsg" element={<CommentSection/>}/>
                <Route path="/" element={<CommentSection/>}/>
                <Route path="/personas" element={<PersonaList/>}/>
                <Route path="/create-persona" element={<CreatePersona/>}/>
                <Route path="/updatePersona/:personaName/:author/:version"
                       element={<UpdatePersonaPage/>}/> {/* Route for updating persona */}
                <Route path="/page" element={<CommentSection/>}/>
                <Route path="/stream" element={<StreamingChat handleAddResponse={handleAddResponse}/>}/>
                <Route path="/persona/:personaName/:author/:version" element={<PersonaRenderer/>}/>
            </Routes>
        </Router>
    );
}

export default App;
