import React, {useEffect, useState} from 'react';
import {Box, HStack, StackDivider, VStack} from '@chakra-ui/react';
import AddTopic from './AddTopic';
import Loading from './Loading';
import CommentList from './CommentList';
import TopicList from './TopicList';
import commentServices from '../services/commentServices';
import {Comments, Topic, TopicComments, Topics} from '../types';
import {useParams, useNavigate, useLocation} from 'react-router-dom';
import slugify from "slugify";
import { Container } from '@chakra-ui/react';

const CommentSection: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [topicComments, setTopicComments] = useState<TopicComments>();
    const [topics, setTopics] = useState<Topics>([]);
    const {topicId, topicMsg} = useParams<{ topicId: string; topicMsg?: string }>();
    const navigate = useNavigate();
    const location = useLocation();

    const unslugify = (slug: string | undefined) => {
        if (slug) {
            return slug.replace(/-/g, ' ')
                .replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
        } else {
            return "";
        }
    };

    const fetchLatestTopicComments = async (topics: Topics) => {
        const latestTopic = topics.reduce((latest, current) => {
            return new Date(latest.updated_at) < new Date(current.updated_at) ? current : latest;
        }, topics[0]);

        if (latestTopic) {
            const topicWithComments = await commentServices.getTopic(latestTopic.id.toString());
            setTopicComments(topicWithComments);
        }
    };

    const handleFetchTopicsAndComments = async () => {
        setLoading(true);
        try {
            const fetchedTopics = await commentServices.getTopics();
            setTopics(fetchedTopics);

            if (fetchedTopics && fetchedTopics.length > 0) {
                await fetchLatestTopicComments(fetchedTopics);
            }
        } catch (error) {
            console.log('Error fetching topics or comments:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleFetchCommentsByTopic = async (id: string) => {
        try {
            setLoading(true);
            const topicWithComments = await commentServices.getTopic(id);
            setTopicComments(topicWithComments);
        } catch (error) {
            console.log('error fetching comments by topic:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleFetchTopics = async () => {
        try {
            setLoading(true);
            const topics = await commentServices.getTopics();
            setTopics(topics);
        } catch (error) {
            console.log('error');
        } finally {
            setLoading(false);
        }
    };

    const handleAddTopic = async (newTopic: Topic) => {
        try {
            setTopics(prevTopics => [newTopic, ...prevTopics]);

            // Navigate to the new topic
            navigate(`/topic/${newTopic.id}/${slugify(newTopic.message, {lower: true, strict: true})}`);

            // Fetch comments for the new topic
            await handleFetchCommentsByTopic(newTopic.id.toString());
        } catch (error) {
            console.log('Error adding topic:', error);
        }
    };

    useEffect(() => {
        if (topicId) {
            document.title = unslugify(topicMsg);
            handleFetchCommentsByTopic(topicId);
            handleFetchTopics();
        } else {
            handleFetchTopicsAndComments();
        }
    }, [topicId, topicMsg]);

    return (
        <Container maxW="container.lg" p={0}>
            <HStack
                divider={<StackDivider borderColor="gray.200"/>}
                flexDir={{base: 'column', md: 'row'}}
                position="relative"
                align="flex-start"
                spacing={4}
                width="100%"
                my={4}
            >
                <Box
                    w={{base: '100%', md: '40%'}}
                    mb={{base: 8, md: 0}}
                    position={{base: 'static', md: 'sticky'}}
                    top={{base: 0, md: 8}}
                    left={0}
                >
                    <AddTopic handleAddTopic={handleAddTopic}/>
                    <TopicList topics={topics}/>
                </Box>
                <Box w={{base: '100%', md: '60%'}}>
                    {loading ? <Loading/> : <CommentList topicComments={topicComments}/>}
                </Box>
            </HStack>
        </Container>
    );
};

export default CommentSection;