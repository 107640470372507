import React, {useEffect, useState} from 'react';
import {Box, Button, SimpleGrid, Heading, Text} from '@chakra-ui/react';
import {useNavigate} from 'react-router-dom';
import {Persona} from '../types';
import personaServices from '../services/PersonaServices';
import { Container } from '@chakra-ui/react';

const PersonaList = () => {
    const [personas, setPersonas] = useState<Persona[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPersonas = async () => {
            const fetchedPersonas = await personaServices.getPersonas('web1');  // Adjust this function to fetch persona list from your API
            setPersonas(fetchedPersonas);
        };
        fetchPersonas();
    }, []);

    const handleEdit = (persona: Persona) => {
        const personaId = `${persona.name}_${persona.author}_${persona.version}`; // Construct personaId using name, author, and version
        navigate(`/updatePersona/${persona.name}/${persona.author}/${persona.version}`); // Pass persona details to the URL
    };

    return (
        <Container maxW="container.lg" p={0} minH="100vh">
            <Box p={5}>
                <Heading mb={6}>All Personas</Heading>
                <SimpleGrid columns={[1, 2, 3]} spacing={10}>
                    {personas.map((persona) => (
                        <Box key={persona.id} p={5} shadow="md" borderWidth="1px">
                            <Heading fontSize="xl">{persona.name}</Heading>
                            <Text mt={4}>{persona.desc}</Text>
                            <Button mt={4} onClick={() => handleEdit(persona)}>
                                Edit Persona
                            </Button>
                        </Box>
                    ))}
                </SimpleGrid>
            </Box>
        </Container>
    );
};

export default PersonaList;
