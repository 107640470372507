import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Flex, Image, Box} from '@chakra-ui/react';
import {MdAdd} from 'react-icons/md';
import Button from './shared/Button';
import ThemeToggler from './ThemeToggler';
import Disclaimer from './Disclaimer';
import {Container} from '@chakra-ui/react';

const Navbar = () => {
    const navigate = useNavigate();

    const handleCreatePersona = () => {
        navigate('/create-persona');
    };

    return (
        <Container maxW="container.lg" p={0}>
            <Flex align="center" justify="space-between" px={4} py={2} boxShadow="sm">
                <Image src="/logo.png" alt="AiPersonas" boxSize="40px" mr={2}/>

                <Flex align="center">
                    <Button
                        leftIcon={<MdAdd/>}
                        onClick={handleCreatePersona}
                        mr={2}
                        bg="orange.200"
                        color="orange.800"
                        _hover={{bg: "orange.300"}}
                        height="32px"
                        fontSize="sm"
                        px={3}
                    >
                        Create Persona
                    </Button>
                    <Disclaimer/>
                    <ThemeToggler display={{base: 'none', md: 'flex'}} align="center" ml={2}/>
                </Flex>
            </Flex>
        </Container>
    );
};

export default Navbar;